import { Controller } from "@hotwired/stimulus"

export default class extends Controller {
  static targets = ["input"]

  toggle(event) {
    const radio = event.target
    if (radio.checked && radio.dataset.lastChecked) {
      radio.checked = false
      delete radio.dataset.lastChecked
    } else {
      radio.dataset.lastChecked = true
    }
  }

  inputTargetConnected(element) {
    element.addEventListener("click", this.toggle)
  }
}