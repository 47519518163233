import { Controller } from "@hotwired/stimulus";

// Connects to data-controller="referral-filter"
export default class extends Controller {
  static targets = ["searchInput"];

  connect() {
    this.closeDropdownFilter();
    this.toggleSelectAllButton(this.element);
  }

  search(event) {
    const searchTerm = this.searchInputTarget.value.trim().toLowerCase();
    const dropdownItems = event.target
      .closest(".list-group")
      .querySelectorAll(".dropdown-filter-item");

    dropdownItems.forEach((item) => {
      const textContent = item.textContent.trim().toLowerCase();
      if (searchTerm === "" || textContent.includes(searchTerm)) {
        item.classList.remove("d-none");
      } else {
        item.classList.add("d-none");
      }
    });
  }

  clickDropdownFilterItem(event) {
    const dropdownFilterItem = event.target.closest(".dropdown-filter-item");
    dropdownFilterItem.classList.toggle("active-item");
    const dropdown = event.target.closest(".custom-dropdown");
    const el = dropdown.querySelector('input.filter-input[type="hidden"]');
    const originValue = el.value;
    const selectedValue =
      event.target.closest("li").dataset.dropdownFilterItemValue;
    el.disabled = false;

    if (!dropdownFilterItem.classList.contains("active-item")) {
      el.value = originValue
        .split(", ")
        .filter((value) => value !== selectedValue)
        .join(", ");
    } else if (
      !originValue.split(",").some((value) => value === selectedValue)
    ) {
      el.value = originValue
        ? `${originValue}, ${selectedValue}`
        : selectedValue;
    }
    this.toggleSelectAllButton(dropdown);
  }

  toggleDropdown(event) {
    document.querySelectorAll(".custom-dropdown").forEach((dropdown) => {
        dropdown.classList.add("d-none");
    });

    const dropdown = event.target
        .closest(".filter-item")
        .querySelector(".custom-dropdown");

    this.toggleSelectAllButton(dropdown);

    dropdown.classList.toggle("d-none");
  }

  closeDropdownFilter() {
    document.addEventListener("click", (event) => {
      if (!event.target.closest(".filter-item")) {
        this.handleFocusOutFilter();
      }
    });
  }

  handleFocusOutFilter() {
    setTimeout(() => {
      const openDropdown = document.querySelector(
        ".custom-dropdown:not(.d-none)"
      );

      if (openDropdown) {
        const form = openDropdown.closest("form");
        if (form) {
          this.submitForm(form);
        }
        openDropdown.classList.add("d-none");
      }
    }, 100);
  }

  submitForm(form) {
    const { isFilter, isChangedFilterValue } =
      this.disableEmptyHiddenInputs(form);
    const urlParams = new URLSearchParams(window.location.search);

    if ((isFilter || urlParams.get("filter")) && isChangedFilterValue)
      form.requestSubmit();
  }

  disableEmptyHiddenInputs(form) {
    const hiddenInputs = form.querySelectorAll(
      'input.filter-input[type="hidden"]'
    );
    hiddenInputs.forEach((el) => {
      if (el.value === "") {
        el.disabled = true;
      }
    });

    const allFilterInputsAreBlank = Array.from(hiddenInputs).every(
      (el) => el.value === ""
    );
    const filterInput = form.querySelector('input[name="filter"]');
    if (allFilterInputsAreBlank) {
      filterInput.disabled = true;
    } else {
      filterInput.disabled = false;
    }

    const urlParams = new URLSearchParams(window.location.search);
    const isFiltersValueMatchUrlParams = Array.from(hiddenInputs).every(
      (el) =>
        el.value === urlParams.get(el.name) ||
        (el.value === "" && !urlParams.get(el.name))
    );

    return {
      isFilter: !filterInput.disabled,
      isChangedFilterValue: !isFiltersValueMatchUrlParams,
    };
  }

  toggleSelectAllButton(dropdown) {
    const selectAllButton = dropdown.querySelector('.select-all');
    const activeItems = dropdown.querySelectorAll(".dropdown-filter-item.active-item");

    if (activeItems.length > 0) {
      selectAllButton.textContent = "Deselect All";
    } else {
      selectAllButton.textContent = "Select All";
    }
  }

  selectOrDeselectAll(event) {
    const dropdown = event.target.closest(".custom-dropdown");

    const hiddenInput = dropdown.querySelector('input.filter-input[type="hidden"]');
    const items = dropdown.querySelectorAll(".dropdown-filter-item");
    const activeItems = dropdown.querySelectorAll(".dropdown-filter-item.active-item");

    if (activeItems.length > 0) {
        hiddenInput.value = "";
        hiddenInput.disabled = true;
        items.forEach((item) => {
            item.classList.remove("active-item");
        });
    } else {
        const allIds = Array.from(items).map(item => item.dataset.dropdownFilterItemValue);
        hiddenInput.value = allIds.join(", ");
        hiddenInput.disabled = false;
        items.forEach((item) => {
            item.classList.add("active-item");
        });
    }

    this.toggleSelectAllButton(dropdown);
  }
}
