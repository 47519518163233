import { Controller } from "@hotwired/stimulus";

export default class extends Controller {
  static targets = [ "confirmationModalContainer" ]

  showModal(event) {
    this.createModal(event.target);
  }

  confirmationModalContainerTargetConnected() {
    localStorage.setItem('isShowModal', true);
  }

  confirmationModalContainerTargetDisconnected() {
    localStorage.setItem('isShowModal', false);
  }

  createModal(element) {
    // This is the HTML structure we want to create:
    // <div class="modal fade" id="confirmationModal" tabindex="-1" aria-labelledby="confirmationModalLabel" aria-hidden="true" data-controller="confirmation-modal">
    //   <div class="modal-dialog">
    //     <div class="modal-content pdt-48 pdl-48 pdb-48 pdr-48" style="border-radius: 24px; width: 700px">
    //       <div class="modal-header border-bottom-0">
    //         <div class="modal-title font-32 secondary-font" id="confirmationModalLabel">
    //           <div>Are you sure?</div>
    //         </div>
    //       </div>
    //       <% if local_assigns[:has_content] %>
    //         <div class="modal-body font-18">
    //           <div>This is the content</div>
    //         </div>
    //       <% end %>
    //       <div class="modal-footer border-top-0">
    //         <div class="d-flex gap-3 w-100">
    //           <div class="w-50">
    //             <button type="button" class="btn btn-light w-100" data-bs-dismiss="modal">Never mind</button>
    //           </div>
    //           <div class="w-50">
    //             <button type="button"
    //                     id="submitBtn"
    //                     data-action="click->confirmation-modal#confirm"
    //                     data-bs-dismiss="modal"
    //                     class="btn btn-active w-100"
    //                     data-action-path="/path"
    //                     data-method="method"
    //             >Yes, I’m sure</button>
    //           </div>
    //         </div>
    //       </div>
    //     </div>
    //   </div>
    // </div>

    const { title, content, noMessage, yesMessage, actionPath, method, anchorId } = element.dataset;

    // Create the main modal div
    const modal = document.createElement('div');
    modal.className = 'modal fade';
    modal.id = 'confirmationModal';
    modal.tabIndex = -1;
    modal.setAttribute('aria-labelledby', 'confirmationModalLabel');
    modal.setAttribute('aria-hidden', 'true');
    modal.setAttribute('data-controller', 'confirmation-modal');

    // Create the modal-dialog div
    const modalDialog = document.createElement('div');
    modalDialog.className = 'modal-dialog';

    // Create the modal-content div
    const modalContent = document.createElement('div');
    modalContent.className = 'modal-content pdt-48 pdl-48 pdb-48 pdr-48';
    modalContent.style.borderRadius = '24px';
    // modalContent.style.width = '700px';

    // Create the modal-header div
    const modalHeader = document.createElement('div');
    modalHeader.className = 'modal-header border-bottom-0';
    // Create the modal-title div
    const modalTitle = document.createElement('div');
    modalTitle.className = 'modal-title font-32 secondary-font';
    modalTitle.id = 'confirmationModalLabel';
    // Create the title content
    const titleContent = document.createElement('div');
    titleContent.textContent = title || 'Are you sure?';
    modalTitle.appendChild(titleContent);
    // Append title and close button to the modal header
    modalHeader.appendChild(modalTitle);
    modalContent.appendChild(modalHeader);

    // Create the modal-body div
    if (content) {
      const modalBody = document.createElement('div');
      modalBody.className = 'modal-body font-18';
      // Create the body content
      const bodyContent = document.createElement('div');
      bodyContent.textContent = content;
      modalBody.appendChild(bodyContent);
      // Append body to the modal content
      modalContent.appendChild(modalBody);
    }

    // Create the modal-footer div
    const modalFooter = document.createElement('div');
    modalFooter.className = 'modal-footer border-top-0';
    // Create the buttons wrapper
    const buttonsWrapper = document.createElement('div');
    buttonsWrapper.className = 'd-flex gap-3 w-100';
    // Create the cancel button wrapper
    const cancelButtonWrapper = document.createElement('div');
    cancelButtonWrapper.className = 'w-50';
    // Create the cancel button
    const cancelButton = document.createElement('button');
    cancelButton.type = 'button';
    cancelButton.className = 'btn btn-light w-100';
    cancelButton.setAttribute('data-bs-dismiss', 'modal');
    cancelButton.textContent = noMessage || 'Never mind';
    cancelButtonWrapper.appendChild(cancelButton);
    // Create the yes button wrapper
    const yesButtonWrapper = document.createElement('div');
    yesButtonWrapper.className = 'w-50';
    // Create the yes button
    const yesButton = document.createElement('button');
    yesButton.type = 'button';
    yesButton.id = 'submitBtn';
    yesButton.className = 'btn btn-active w-100';
    yesButton.setAttribute('data-action', 'click->confirmation-modal#confirm');
    yesButton.setAttribute('data-bs-dismiss', 'modal');
    yesButton.textContent = yesMessage || 'Yes, I’m sure';
    yesButton.dataset.actionPath = actionPath;
    yesButton.dataset.method = method;
    yesButton.dataset.anchorId = anchorId;
    yesButtonWrapper.appendChild(yesButton);
    // Append buttons to the buttons wrapper
    buttonsWrapper.appendChild(cancelButtonWrapper);
    buttonsWrapper.appendChild(yesButtonWrapper);
    // Append the buttons wrapper to the modal footer
    modalFooter.appendChild(buttonsWrapper);

    // Append footer to the modal content
    modalContent.appendChild(modalFooter);

    // Append modal content to the modal dialog
    modalDialog.appendChild(modalContent);

    // Append modal dialog to the main modal div
    modal.appendChild(modalDialog);

    // Append the modal to the body (or any other container)
    document.body.appendChild(modal);

    // Show the modal
    $(modal).modal('show').on('hidden.bs.modal', function () {
      localStorage.removeItem('redirectToPath');
    });
  }

  confirm(e) {
    localStorage.setItem('isShowModal', false);

    if(localStorage.getItem('redirectToPath')) {
      const a = document.createElement('a');
      a.href = localStorage.getItem('redirectToPath');
      document.body.appendChild(a);
      a.click();
      localStorage.removeItem('redirectToPath');
      return;
    }

    const { actionPath, method } = e.target.dataset;

    if (method === "get") {
      window.location.href = actionPath;
      return;
    }

    const form = document.createElement('form');
    form.method = "POST";
    form.action = actionPath;
    form.style.display = 'none';

    const csrfToken = document.querySelector('meta[name="csrf-token"]').content;
    const csrfInput = document.createElement('input');
    csrfInput.type = 'hidden';
    csrfInput.name = 'authenticity_token';
    csrfInput.value = csrfToken;
    form.appendChild(csrfInput);

    const methodInput = document.createElement('input');
    methodInput.type = 'hidden';
    methodInput.name = '_method';
    methodInput.value = method;
    form.appendChild(methodInput);

    document.body.appendChild(form);
    form.requestSubmit();
  }

  turnOffModalMode() {
    localStorage.setItem('isShowModal', false);
  }
}
