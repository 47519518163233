import { Controller } from "@hotwired/stimulus"

export default class extends Controller {
  static targets = ["timeRange", "inboundSection", "outboundSection", "selectionText"]

  connect() {
    this.showInbound()
  }

  selectTimeRange(event) {
    event.preventDefault()
    const selectedRange = event.target.dataset.range
    const url = new URL(window.location.href)
    url.searchParams.set("time_range", selectedRange)
    window.location.href = url.toString()
  }

  showInbound() {
    this.inboundSectionTarget.classList.remove("d-none")
    this.outboundSectionTarget.classList.add("d-none")
    this.selectionTextTarget.textContent = "Inbound"
  }

  showOutbound() {
    this.outboundSectionTarget.classList.remove("d-none")
    this.inboundSectionTarget.classList.add("d-none")
    this.selectionTextTarget.textContent = "Outbound"
  }
}
