import { Controller } from "@hotwired/stimulus"
import { checkNewReferralFormSubmitButton, getReferralSubmitButton } from "../helpers";
import { Tooltip } from 'bootstrap'


export default class extends Controller {
  static targets = [
    "patientForm",
    "searchPatientForm",
    "addNewPatientButton",
    "patientDzItemsWrapper",
    "patientDzItems",
    "patientInput",
    "patientConsentCheckBox",
    "checkboxSection"
  ]

  connect() {
    this.initializeConsentCheckbox();
  }

  chooseExistingPatient(event) {
    this.patientFormTarget.classList.add('d-none');
    this.searchPatientFormTarget.classList.remove('d-none');
    this.addNewPatientButtonTarget.classList.remove('d-none');
    this.checkboxSectionTarget.classList.add('d-none');
  }

  patientInputTargetConnected(e) {
    e.addEventListener("focusout", (e) => {
      setTimeout(() => {
        this.patientDzItemsWrapperTarget.classList.add("d-none");
      }, 200)
    });
  }

  searchPatients(e){
    setTimeout(() => {
      const searchText = e.target.value;

      if (!searchText.length) {
        this.patientDzItemsWrapperTarget.classList.add('d-none');
        return;
      }

      $.ajax({
        url: `/referrals/patients_by_organization`,
        type: 'GET',
        dataType: 'HTML',
        data: { search_text: searchText },
        success: (data) => {
          this.patientDzItemsTarget.innerHTML = data;
        }
      });

      this.patientDzItemsWrapperTarget.classList.remove('d-none');
    }, 300);
  }

  addNewPatient(event) {
    this.patientFormTarget.classList.remove('d-none');
    this.searchPatientFormTarget.classList.add('d-none');
    this.addNewPatientButtonTarget.classList.add('d-none');
    this.checkboxSectionTarget.classList.remove('d-none');
  }

  initializeConsentCheckbox() {
    if (!this.hasPatientConsentCheckBoxTarget) return;

    $('.patient-content').on('change', 'input[type=checkbox][required]', (e) => {
      checkNewReferralFormSubmitButton();
      this.validatePatientConsentCheckbox(e.target);
    })
  }

  validatePatientConsentCheckbox($el) {
    console.log($el)
    const $form = $el.closest('form');
    const submitBtn = getReferralSubmitButton();

    const patientConsentCheckBox = $el
    const isPatientFieldUnchecked = patientConsentCheckBox && !patientConsentCheckBox.checked

    if (isPatientFieldUnchecked) {
      submitBtn.setAttribute("title", "Please click the checkbox to confirm this patient gave their consent for Sindi to reach out");
      submitBtn.setAttribute("data-bs-toggle", "tooltip");
      submitBtn.setAttribute("data-bs-placement", "bottom");

      new Tooltip(submitBtn)
    } else {
      this.disableButtonTooltip();
    }
  }

  disableButtonTooltip() {
    const tooltipInstance = Tooltip.getInstance(getReferralSubmitButton());
    tooltipInstance && tooltipInstance.dispose();
  }

  selectPatient(event) {
    const patientId = event.target.dataset.patientId;
    const patientConsent = event.target.dataset.patientConsent;

    this.patientFormTarget.classList.remove('d-none');
    this.searchPatientFormTarget.classList.add('d-none');
    this.addNewPatientButtonTarget.classList.add('d-none');
    this.checkboxSectionTarget.classList.remove('d-none');

    if (this.hasPatientConsentCheckBoxTarget) {
      this.patientConsentCheckBoxTarget.checked = patientConsent;
    }

    this.patientInputTarget.value = event.target.dataset.patientName;
  }

  clearPatientInfo(event) {
    this.patientFormTarget.classList.remove('d-none');
    this.searchPatientFormTarget.classList.add('d-none');
    this.addNewPatientButtonTarget.classList.remove('d-none');

    this.patientInputTarget.value = "";

    if (this.hasPatientConsentCheckBoxTarget) {
      this.patientConsentCheckBoxTarget.checked = false;
    }
  }
}
