import { Controller } from "@hotwired/stimulus";

export default class extends Controller {
  static targets = ["toggle"];

  connect() {
    this.toggleTargets.forEach((toggle) => {
      toggle.addEventListener("change", (event) => this.updateConfig(event));
    });
  }

  submitForm(event, previewing) {
    event.preventDefault()
    if(!previewing) this.closePreview()
    event.target.closest(".referral-form-config-section").querySelector('[name=previewing]').value = previewing == undefined ? '' : previewing;
    event.target.closest("form").requestSubmit();
  }

  addNewField(event) {    
    const field = document.querySelector('.sample-option .referral-form-config-field')
    const section = event.target.closest(".referral-form-config-section")
    const section_key = section.dataset.sectionKey
    const allFields = section.querySelectorAll(".referral-form-config-field")
    const fieldsContainer = section.querySelector(".referral-form-config-fields")

    const lastField = allFields[allFields.length - 1]
    const lastInd = lastField ? parseInt(lastField.querySelector('[name*="[order]"]').value) : 0
    
    if(allFields.length == 0) {
      fieldsContainer.classList.remove('d-none')
      section.querySelector(".empty-state").classList.add('d-none')
    }
    var newElement = field.cloneNode(true);
    fieldsContainer.querySelector(".referral-form-config-section-actions").insertAdjacentElement('beforebegin', newElement)
    const fieldType = event.target.dataset.fieldType;
    newElement.dataset.fieldType = fieldType
    newElement.querySelector('.field-title').innerHTML = (fieldType === 'yes_no' ? 'Yes/No' : fieldType.replace(/_/g, ' ').toUpperCase())
    newElement.querySelector('.add-new-item-btn').dataset.fieldType = fieldType
    const elements = newElement.querySelectorAll('[name*="referral_form_config[custom_sample_section][fields]"]')
    elements.forEach(el => {
      if(el.name.includes('[field_type]'))  el.value = fieldType
      el.name = el.name.replace('custom_sample_section', section_key)
      el.id = el.id.replace('custom_sample_section', section_key)
      if(el.name.includes('[order]')) el.value = lastInd + 1 // Set the order of the new field to be the last field
    })      
    if (!['single_select', 'multiple_choice', 'dropdown_list'].includes(fieldType)) {
      newElement.querySelector('.options-section').innerHTML = ''
      newElement.querySelector('.add-new-item-btn').remove()
      if(['yes_no'].includes(fieldType)) {
        this._addNewOption(newElement, fieldType, null)
      }
    }
    this.propagateChanges(event)

  }

  deleteField(event) {
    const hiddenInput = event.target.closest(".referral-form-config-field").querySelector("input[type=hidden].delete-field");
    const field = event.target.closest(".field");
    const section = event.target.closest(".referral-form-config-section")
    field.remove();
    this.propagateChanges(event, section)
  }

  duplicateField(event) {
    const field = event.target.closest(".field");
    var clonedElement = field.cloneNode(true);
    clonedElement.querySelector('.action-menu .action-dashboard')?.classList.remove('show')
    clonedElement.querySelector('.action-menu .dropdown-menu')?.classList.remove('show')
    field.insertAdjacentElement('afterend', clonedElement);
  }

  addNewOption(event) {
    const container = event.target.closest('.referral-form-config-field')
    this._addNewOption(container, container.dataset.fieldType, 1)
  }

  _addNewOption(field, fieldType, limit) {
    const optionsSection = field.querySelector('.options-section')
    const sampleOptions = document.querySelectorAll('.sample-option .referral-form-config-field .option')
    const section_key = field.closest(".referral-form-config-section").dataset.sectionKey
    limit = limit || sampleOptions.length;
    if(fieldType == 'yes_no') limit = 2
    for(let i = 0; i < limit; i++)  { 
      const newOption = sampleOptions[i].cloneNode(true)
      newOption.querySelector('input[type=text]').value = (fieldType === 'yes_no' ? i == 0 ? "Yes" : "No" : 'New Option')
      if(fieldType == 'yes_no') newOption.querySelector('.delete-option').remove();
      newOption.querySelectorAll('input').forEach(el => {
        el.name = el.name.replace('custom_sample_section', section_key)
        el.id = el.id.replace('custom_sample_section', section_key)
      })
      optionsSection.appendChild(newOption)
    }
  }

  deleteOption(event) {
    const option = event.target.closest(".option");
    option.remove();
  }

  deleteNewOption(event) {
    event.target.closest(".new-option").remove();
  }

  orderFields(event) {
    const orderFields = event.target.closest(".order-fields")
    const hiddenOrderInput = orderFields.querySelector("input[type=hidden].order");
    const field = event.target.closest(".field");
    const fieldsContainer = event.target.closest(".referral-form-config-fields")
    const fields = fieldsContainer.querySelectorAll('.field');
    var clonedElement = field.cloneNode(true);

    const direction = event.target.dataset.orderDirection
    let order = parseInt(hiddenOrderInput.value || 1)
    const maxFields = fields.length
    let hasChanges = false

    if(direction == 'up' && order > 1) {
      field.previousElementSibling.insertAdjacentElement('beforebegin', clonedElement);
      order -= 1; hasChanges = true
    }
    if(direction == 'down' && order < maxFields) {
      field.nextElementSibling.insertAdjacentElement('afterend', clonedElement);
      order += 1; hasChanges = true
    }
    if(direction == 'first' && order > 1) {
      fields[0].insertAdjacentElement('beforebegin', clonedElement);
      order = 1; hasChanges = true
    }
    if(direction == 'last' && order < maxFields) {
      fields[fields.length-1].insertAdjacentElement('afterend', clonedElement);
      order = maxFields; hasChanges = true      
    }

    if(hasChanges){
      field.remove()
      this.updateOrderFields(fieldsContainer.querySelectorAll('.field'))
      this.propagateChanges(event, clonedElement.closest('.referral-form-config-section'))
    }
  }

  updateOrderFields(orderFields) {    
    orderFields.forEach((field, index) => {
      const hiddenOrderInput = field.querySelector("input[type=hidden].order")
      const order = index + 1;
      const upControl = field.querySelector('[data-order-direction="up"]')
      const downControl = field.querySelector('[data-order-direction="down"]')
      const firstControl = field.querySelector('[data-order-direction="first"]')
      const lastControl = field.querySelector('[data-order-direction="last"]')
      const reachedTop = (order == 1)
      const reachedBottom = (order == orderFields.length)
      hiddenOrderInput.value = index;
  
      upControl.dataset.action = reachedTop ? '' : 'click->referral-form-config#orderFields'; 
      upControl.classList.toggle('opacity-25', reachedTop); upControl.classList.toggle('cursor-pointer', !reachedTop)
      downControl.dataset.action = reachedBottom ? '' : 'click->referral-form-config#orderFields'; 
      downControl.classList.toggle('opacity-25', reachedBottom); downControl.classList.toggle('cursor-pointer', !reachedBottom)
      firstControl.dataset.action = reachedTop ? '' : 'click->referral-form-config#orderFields'; 
      firstControl.classList.toggle('opacity-25', reachedTop); firstControl.classList.toggle('cursor-pointer', !reachedTop)
      lastControl.dataset.action = reachedBottom ? '' : 'click->referral-form-config#orderFields'; 
      lastControl.classList.toggle('opacity-25', reachedBottom); lastControl.classList.toggle('cursor-pointer', !reachedBottom)
    })
  }

  propagateChanges(event, section = null) {
    const autoSave = document.querySelector('input[type=hidden][name=referral_form_config_auto_save]')
    this.updateModifiedStatus(event, section)

    if (autoSave.value == 'true' || autoSave.value == true) {
      event.preventDefault();
      this.submitForm(event);
    }
  }

  propagateChangesOnEnter(event) {
    if (event.key === "Enter") {
      this.propagateChanges(event)
    }
  }

  updateModifiedStatus(event, _section = null) {
    const section = event.target.closest(".referral-form-config-section") || _section
    const modifiedField = section.querySelector("input[type=hidden].modified-field")
    this.isModified = true
    const btnSubmit = section.querySelector(".btn-submit");
    btnSubmit.classList.remove('btn-lock')
    modifiedField.value = "true";
    
    const allFields = section.querySelectorAll(".referral-form-config-field")
    const fieldsContainer = section.querySelector(".referral-form-config-fields")
    if(allFields.length == 0) {
      fieldsContainer.classList.add('d-none')
      section.querySelector(".empty-state").classList.remove('d-none')
    }
  }

  updateConfig(event) {
    const checkbox = event.target;
    const key = checkbox.dataset.key;
    const section = checkbox.dataset.section;
    const isChecked = checkbox.checked;

    const isRequired = checkbox.dataset.required === "true";

    const enabledCheckbox = this.toggleTargets.find((toggle) => toggle.dataset.key === key && toggle.dataset.required === "false");
    const requiredCheckbox = this.toggleTargets.find((toggle) => toggle.dataset.key === key && toggle.dataset.required === "true");

    const currentEnabled = enabledCheckbox ? enabledCheckbox.checked : false;
    const currentRequired = requiredCheckbox ? requiredCheckbox.checked : false;

    let configUpdate = {
      key: key,
      section: section,
    };

    if (isRequired) {
      configUpdate["required"] = isChecked;
    } else {
      configUpdate["enabled"] = isChecked;
    }

    fetch("/admin/referral_form_configs", {
      method: "POST",
      headers: {
        "Content-Type": "application/json",
        "X-CSRF-Token": document.querySelector("meta[name='csrf-token']").content,
      },
      body: JSON.stringify({
        referral_form_config: configUpdate,
      }),
    })
      .then((response) => response.json())
      .then((data) => {
        console.log("Config updated", data);
      })
      .catch((error) => {
        console.error("Error:", error);
      });
  }

  togglePreview(event) {
    event.preventDefault()
    const formLeft = document.querySelector('.referral-form-body-left');
    const formRight = document.querySelector('.referral-form-body-right');
    formRight.classList.remove('d-none');
    formLeft.classList.remove('w-75');
    this.submitForm(event, true)
  }

  closePreview(event) {
    this.isPreviewing = null
    const formLeft = document.querySelector('.referral-form-body-left');
    const formRight = document.querySelector('.referral-form-body-right');
    formRight.classList.add('d-none');
    formLeft.classList.add('w-75');
  }

  togglePreview2(event) {
    event.preventDefault()
    const formLeft = document.querySelector('.referral-form-body-left');
    const formRight = document.querySelector('.referral-form-body-right');
    const imageWaiting = document.querySelector('.image-waiting');

    if (formRight) {
      formRight.classList.toggle('d-none');
      formLeft.classList.toggle('w-75');
    }
    if (imageWaiting) {
      imageWaiting.classList.toggle('d-none');
      formLeft.classList.toggle('w-75');
    }
  }
}
