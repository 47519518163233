import { Controller } from "@hotwired/stimulus"

export default class extends Controller {
  static targets = [ "form", "tab", "searchTextInput", "referralsDropzone", "tabName", "removeBtn", "loadingIcon" ]

  search(event) {
    setTimeout(() => {
      if (this.searchTextInputTarget.value.length >= 2) {
        this.formTarget.requestSubmit()
        this.removeBtnTarget.classList.remove('d-none')
        this.loadingIconTarget.classList.remove('d-none')
      } else {
        this.removeBtnTarget.classList.add('d-none')
      }
    }, 200)

    setTimeout(() => {
      this.loadingIconTarget.classList.add('d-none')
    }, 10000)
  }

  handleClickReferralTab(event) {
    // Handle check dropdown item when clicking
    event.target.closest('.referral-tabs-dropdown').querySelectorAll('.tab-item').forEach((tab) => {
      tab.classList.remove('active')
    });
    event.target.closest('.tab-item').classList.add('active')
    // Set the tab value and tab name
    this.tabTarget.value = event.target.closest('.tab-item').dataset.tabKey
    this.tabNameTarget.innerText = event.target.closest('.tab-item').dataset.tabName
    this.searchTextInputTarget.focus()
    this.formTarget.requestSubmit()
  }

  handleFocusOutInput(event) {
    setTimeout(() => {
      if (this.hasReferralsDropzoneTarget) {
        this.referralsDropzoneTarget.classList.add("d-none");
      }
    }, 200)
  }

  resetInputValue() {
    this.searchTextInputTarget.value = ""
    this.removeBtnTarget.classList.add('d-none')
  }

  referralsDropzoneTargetConnected(e) {
    this.loadingIconTarget.classList.add('d-none')
  }
}
